import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './assets/main.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSquare, faObjectGroup } from '@fortawesome/free-regular-svg-icons'

/* add icons to the library */
library.add(faSquare, faObjectGroup)

createApp(App).use(store).use(router).component('font-awesome-icon', FontAwesomeIcon)
    .mount('#app')
