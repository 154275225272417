<template>
  <div :class="[
    'footer',
    'footerObject',
    $store.state.settings.theme,
    $store.state.settings.accessibility.txtgradient,
    $store.state.settings.accessibility.txtstyle,
  ]">
    <div class="columns">
      <div class="column">
        <div class="box listing">
          <p class="small-text" style="text-align: center">
            <b>© First Choice Designs LLC</b> - <b>2023</b>
          </p>
          <p class="small-text" style="text-align: center">2008 Cosgrove Ave, North Charleston, SC 29405</p>
          <p class="small-text" style="text-align: center">
            Hours | Monday - Friday | 8am-5pm
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
export default {
  name: "FooterObject",
  components: {},
  props: [],
  data() {
    return {};
  },
  methods: {},
};
</script>
