<template>
  <NavBar></NavBar>
  <div
    id="home"
    :class="[
      $store.state.settings.theme,
      $store.state.settings.accessibility.txtgradient,
      $store.state.settings.accessibility.txtstyle,
    ]"
  >
    <br />
    <img
      :class="[
        'image',
        'is-home-logo',
        'on-hover-grow',
        $store.state.settings.theme == 'dark' ? 'invert100' : '',
      ]"
      src="../assets/img/logo.svg"
    />
    <h1 class="large-text" style="text-align: center">First Choice Designs</h1>
    <br />
    <div class="container mobile home">
      <p style="text-align: left">
        First Choice Designs LLC is a full service residential and commercial architectural design firm
        located in Charleston, SC. We specialize in creative, beautiful, and
        functional custom homes, additions, and more. Being a smaller firm, we
        offer our clients a unique and highly personalized experience that
        ensures your project is imbued with your ideas, and personality. Each
        step of the process- from the initial meeting, to project completion is
        handled with high attention to detail, smooth communication, and a
        strong pursuit of excellence. Our beginnings are deeply rooted in the
        culture rich design traditions of Charleston and its surrounding areas.
        Our designs are balanced in color and detail, high in quality, and above
        all- practical.<br /><br />
      </p>
    </div>
    <div class="container mobile">
      <br />
      <br />
      <div class="columns">
        <div class="column">
          <div :class="'box' + ' ' + $store.state.settings.theme">
            <h5 style="text-align: center; font-weight: bold">
              ── Locations Served ──
            </h5>
            <hr />
            <p style="text-align: left; width: 100%; margin: auto">
              We have a greater presence in the greater Charleston, SC area- but
              we accept clients from anywhere in South Carolina.
            </p>
            <br />
            <div class="columns" style="padding: 5px">
              <div class="column">
                <ul style="list-style: circle; margin-left: 4px">
                  <li style="text-align: left">Charleston, SC</li>
                  <li style="text-align: left">Mt. Pleasant, SC</li>
                  <li style="text-align: left">James Island, SC</li>
                  <li style="text-align: left">Johns Island, SC</li>
                </ul>
              </div>
              <div class="column">
                <ul style="list-style: circle; margin-left: 4px">
                  <li style="text-align: left">Summerville, SC</li>
                  <li style="text-align: left">West Ashley, SC</li>
                  <li style="text-align: left">... & Many More!</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="column">
          <div :class="'box' + ' ' + $store.state.settings.theme">
            <h5 style="text-align: center; font-weight: bold">
              ── Services Overview ──
            </h5>
            <hr />
            <p style="text-align: left; width: 100%; margin: auto">
              We offer a large number of architectural design services, and
              handle custom requests for any "oddball" projects you have.
            </p>
            <br />
            <div class="columns" style="padding: 5px">
              <div class="column">
                <ul style="list-style: circle; margin-left: 4px">
                  <li style="text-align: left">New Construction</li>
                  <li style="text-align: left">Renovations</li>
                  <li style="text-align: left">Additions</li>
                  <li style="text-align: left">Commercial</li>
                </ul>
              </div>
              <div class="column">
                <ul style="list-style: circle; margin-left: 4px">
                  <li style="text-align: left">
                    <a href="https://en.wikipedia.org/wiki/Secondary_suite"
                      >ADU / Secondary Suites</a
                    >
                  </li>
                  <li style="text-align: left">Garages</li>
                  <li style="text-align: left">Porch Designs</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="is-fullheight-20vh"></div>
    <FooterObject></FooterObject>
  </div>
</template>

<style scoped>
</style>
<script>
import NavBar from "@/components/NavBar.vue";
import FooterObject from "@/components/FooterObject.vue";
export default {
  name: "HomeView",
  components: {
    NavBar,
    FooterObject,
  },
  data() {
    return {};
  },
};
</script>
