import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

function lazyLoad(view) {
  return () => import(`@/views/${view}.vue`)
}
const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeView
  },
  {
    path: '/commercial',
    name: 'Commercial',
    component: lazyLoad('CommercialView')
  },
  {
    path: '/residential',
    name: 'Residential',
    component: lazyLoad('ResidentialView')
  },
  {
    path: '/ourteam',
    name: 'Our Team',
    component: lazyLoad('TeamView')
  },
]
const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  document.title = "FCD -" + " " + to.name;
  next();
})
export default router
