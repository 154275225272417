//import { Client } from '@/client/client.js'
import { createStore } from 'vuex'

export default createStore({
  state: {
    //client: Client,
    settings: {
      theme: localStorage.getItem("theme") ? localStorage.getItem("theme") : "light",
      accessibility: localStorage.getItem("accessibility") ? JSON.parse(localStorage.getItem("accessibility")) : {
        txtgradient: "",
        txtstyle: ""
      }
    },
    status: {
      ping: 0,
      connected: false
    },
    store: {
      cart: [],
      stock: [],
      items: new Map(),
      gateway: ""
    }
  },
  getters: {
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
